/* General Styling */
.chat-message {
    margin-bottom: 10px; /* Space between messages */
}

/* Headers */
.chat-message h1 {
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 0; 
}

.chat-message h2 {
    font-size: 1.5em;
    font-weight: bold; 
}

.chat-message h3, 
.chat-message h4,
.chat-message h5,
.chat-message h6 {
    font-weight: bold;
    font-size: 1.1em;
}

/* Text Styles */
.chat-message p {
    margin-bottom: 10px;
}

.chat-message strong,
.chat-message b {
    font-weight: bold;
}

.chat-message em,
.chat-message i {
    font-style: italic;
}

/* Lists */
.chat-message ul, 
.chat-message ol {
    margin-left: 20px; 
    margin-bottom: 10px;
}

.chat-message li {
    margin-bottom: 5px; 
}

/* Links */
.chat-message a {
    color: #007bff; /* Default blue link color */
    text-decoration: underline;
}

/* Code and Blockquotes */
.chat-message code {
    background-color: #f8f9fa;
    padding: 3px; 
    border-radius: 3px;
}

.chat-message pre {
    background-color: #f8f9fa;
    padding: 10px; 
    border-radius: 3px;
    overflow-x: auto;  /* Add horizontal scroll for long code */
}

.chat-message blockquote {
    border-left: 4px solid #ddd;
    padding-left: 15px;
    margin-left: 0;
    color: #777; 
}

.chat-message ul {
    list-style-type: disc; /* Default bullet point style */
}

.chat-message ol {
    list-style-type: decimal; /* Numbered list */
}