.tiptap {
    outline: none;
  }
  
  /* General Styling */
.tiptap {
  margin-bottom: 10px; /* Space between messages */
}

/* Headers */
.tiptap h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: 0; 
}

.tiptap h2 {
  font-size: 1.5em;
  font-weight: bold; 
}

.tiptap h3, 
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  font-weight: bold;
  font-size: 1.3em;
}

/* Text Styles */
.tiptap p {
  margin-bottom: 10px;
}

.tiptap strong,
.tiptap b {
  font-weight: bold;
}

.tiptap em,
.tiptap i {
  font-style: italic;
}

/* Lists */
.tiptap ul, 
.tiptap ol {
  margin-left: 20px; 
  margin-bottom: 10px;
}

.tiptap li {
  margin-bottom: 5px; 
}

.tiptap ul ul, 
.tiptap ol ol {
  list-style-type: square;
}

.tiptap ul ul ul, 
.tiptap ol ol ol {
  list-style-type: circle;
}

/* Links */
.tiptap a {
  color: #007bff; /* Default blue link color */
  text-decoration: underline;
}


/* Code and Blockquotes */
.tiptap code {
  background-color: #f8f9fa;
  padding: 3px; 
  border-radius: 3px;
}

.tiptap pre {
  background-color: #f8f9fa;
  padding: 10px; 
  border-radius: 3px;
  overflow-x: auto;  /* Add horizontal scroll for long code */
}

.tiptap blockquote {
  border-left: 4px solid #ddd;
  padding-left: 15px;
  margin-left: 0;
  color: #777; 
}

.tiptap ul {
  list-style-type: disc; /* Default bullet point style */
}

.tiptap ol {
  list-style-type: decimal; /* Numbered list */
}

.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder); /* Use the data-placeholder attribute */
  color: #adb5bd; /* Adjust the color as needed */
  pointer-events: none; /* Make sure it's not clickable */
}

.ProseMirror.bn-editor.bn-default-styles {
  padding: 0 !important; /* Use !important to override */
}